var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('document-request-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$route.name)+" ")]},proxy:true},{key:"content",fn:function(){return [_c('div',[(_vm.checkPerUser)?_c('v-btn',{staticClass:"t-btn--prm my-3",on:{"click":function($event){_vm.visible = 2}}},[_vm._v(" 選択項目に担当者割振 ")]):_vm._e()],1),_c('v-card',[_c('Table',{ref:"table",attrs:{"filter":_vm.filter,"itemsPerPage":10,"itemsPerPageOptions":[10, 20, 50, 100, 200, 500],"attr":{
          dense: true,
          'no-data-text': _vm.$t('rules.noData'),
          'item-key': 'id',
        },"headers":_vm.headers,"total":_vm.total,"funReset":_vm.getWebRequestlistDocumentRequestlists,"items":_vm.webRequestlistDocumentRequestlistFalse,"sortField":[],"showSelect":true,"multiSort":true},on:{"setSelected":function (value) {
            _vm.selected = value;
          }},scopedSlots:_vm._u([{key:"item.mediasType",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.medias && item.medias.map(function (c) { return c.mediaType; }).join(', ')))])]}},{key:"item.picName",fn:function(ref){
          var item = ref.item;
return [(item.client)?_c('div',[(item.client.pic)?_c('div',[_vm._v(" "+_vm._s(item.client.pic.name)+" ")]):_c('div',[_c('span',{staticClass:"textCustomerSalesRepresentative"},[_vm._v("未割振")])])]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt.substr(5, 2))+"/"+_vm._s(item.createdAt.substr(8, 2))+" ")]}},{key:"item.isCompany",fn:function(ref){
          var item = ref.item;
return [(item.client)?_c('div',[_vm._v(" "+_vm._s(item.client.isCompany ? '法人' : '個人')+" ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [(item.client)?_c('div',[_vm._v(" "+_vm._s(item.client.name)+" ")]):_vm._e()]}},{key:"item.forVmAndforVs",fn:function(ref){
          var item = ref.item;
return [(item.forVm)?_c('span',[_vm._v("VM")]):_vm._e(),(item.forVs)?_c('span',[_vm._v(", VS")]):_vm._e()]}},{key:"item.importMethod",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.importMethod.toUpperCase())+" ")]}},{key:"item.user",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",attrs:{"icon":"","target":"_blank","to":'/customer-details/' +
                item.client.id +
                '?typeClient=' +
                item.client.type},on:{"click":function($event){_vm.idDoc = item.id}}},[_c('v-icon',[_vm._v("mdi-account")])],1)]}},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"btn-crm-primary",staticStyle:{"border":"0px"},attrs:{"disabled":!_vm.checkPerUser,"icon":""},on:{"click":function($event){return _vm.handleClickEdit(item)}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-pencil-circle")])],1)]}}],null,true)})],1),(_vm.checkPerUser)?_c('documentRequestDetailCorporation',{attrs:{"visible":_vm.visible === 0,"dataUrl":_vm.data},on:{"close":function($event){_vm.visible = -1},"reloadList":_vm.reloadList}}):_vm._e(),(_vm.checkPerUser)?_c('documentRequestDetailIndividual',{attrs:{"visible":_vm.visible === 1,"dataUrl":_vm.data},on:{"close":function($event){_vm.visible = -1},"reloadList":_vm.reloadList}}):_vm._e(),(_vm.checkPerUser)?_c('SalesRepresentativeAllocation',{attrs:{"visible":_vm.visible === 2,"webRequestListDocumentRequestListSelected":_vm.selected},on:{"close":function($event){_vm.visible = -1}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }